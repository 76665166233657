
import { smoothScrollToTargetId } from '@/helpers/util'
export default {
  name: 'SectionContactV1',
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hideStickyContact: true,
      contacts: this.data?.stickyContacts || [],
      randomContact: null
    }
  },
  created() {
    this.$nextTick(() => {
      this.randomContact = this.randomItem(this.contacts)
    })
  },
  methods: {
    randomItem(contacts) {
      return contacts[Math.floor(Math.random() * contacts.length)]
    },
    resourceUrl(img) {
      if (img.includes('://')) {
        return img
      }
      return `${this.$store.getters.cdnBase}/${img}`
    },
    goToNextSection() {
      smoothScrollToTargetId(`#${this.hash}`)
    },
    handleHideStickyContact() {
      this.hideStickyContact = false
    }
  }
}
